import React, { useState, useRef } from "react";
import PhotoHome from "../Picture/20220724_154730_HDR.jpg";
import Movie from "../Picture/bruit.mp4";
import "../Style/HomePage.css";
import Slider from "../Component/Slider";
import Titineamour from "../Picture/Titineamour.jpg";
import Titinebanc from "../Picture/Titinebancmovie.mp4";
import Moteur from "../Picture/Moteur.jpg";
import Boitedevitesse from "../Picture/Boitedevitesse.jpg";
import reservoir from "../Picture/reservoir.png";
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import Toastifybook from "../Component/Toastifybook";

function HomePage() {
  const [soundEnabled, setSoundEnabled] = useState(true);
  const [puissanceEnabled, setPuissanceEnabled] = useState(false);
  const videoRef = useRef(null);
  const topRef = useRef(null);
  const topReftwo = useRef(null);
  const videobancRef = useRef(null);

  const toggleSound = () => {
    if (videoRef.current && videobancRef.current) {
      videoRef.current.muted = !soundEnabled;
      setSoundEnabled(!soundEnabled);
      if (soundEnabled) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  };

  const scrollToTop = () => {
    if (topRef.current) {
      topRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const scrollToToptwo = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleClickVideobanc = () => {
    if (videobancRef.current) {
      if (videobancRef.current.paused) {
        videobancRef.current.play();
        setPuissanceEnabled(true);
      } else {
        videobancRef.current.pause();
        setPuissanceEnabled(false);
      }
    }
  };

  const handleClickVideo = () => {
    if (videoRef.current) {
      if (videoRef.current.paused) {
        videoRef.current.play();
      } else {
        videoRef.current.pause();
      }
    }
  };

  return (
    <div ref={topReftwo}>
    <script
      src="https://kit.fontawesome.com/ca4cc989d7.js"
      crossOrigin="anonymous"
    ></script>
    <div className="container">
    <img className="PhotoHome" src={PhotoHome} alt="Photo Home" loading="lazy"/>
      <div className="centered-text">
        <h1>
          Bienvenue sur Titine land
          <br />
          le site de Titine pour Titine
        </h1>
      </div>
      <button className="buttonhome" onClick={scrollToTop}>
        Titine Découverte
      </button>
    </div>
    <div ref={topRef}></div>
    <div className="container-card">
      <div className="card-text">
        <h1>Titine Chérie</h1>
        <p>
          Titine est une Harley Davidson Sportster XL 883 Low de 2005, appartenant
          aux derniers modèles équipés d'un carburateur, ce qui lui confère une
          douce symphonie. Sa silhouette élégante et son charme indélébile sont
          typiques des modèles "XL Low", offrant ainsi une position de conduite
          basse et facile pour tous. Sa douceur d'esprit et sa force de caractère
          en feront la meilleure des partenaires pour parcourir des milliers de
          kilomètres.
        </p>
      </div>
      <div className="movie" >
        <video 
        loading="lazy"
          ref={videoRef}
          src={Movie}
        controls playsinline
          autoPlay
          loop
          muted
          onClick={handleClickVideo}
        ></video>
      </div>
    </div>
    <div className="caintainerbutton">
      <button className="titinesong" onClick={toggleSound}>
        {soundEnabled ? "Activer le Titine son" : "Désactiver le Titine son"}
      </button>
    </div>
    <h1 className="title">Le Titine Slider</h1>
    <Slider />
    <h1 className="titinedes">La Titine Description</h1>
    <div className="container-card-pieces">
      <div className="description-and-pictures">
        <div className="Pieces">
          <img className="moteur" src={Moteur} alt="moteur" loading="lazy"/>
          <img className="boite" src={Boitedevitesse} alt="boite" loading="lazy"/>
          <img className="reservoir" src={reservoir} alt="reservoir" loading="lazy"/> 
        </div>
        <div className="card-text-two">
          <h1>Fiche technique</h1>
          <p>
            CHÂSSIS <br />
            Cadre : Double berceau tubulaire en acier <br />
            Réservoir : 12,50 litres <br />
            Hauteur de selle : 641 mm <br />
            Longueur : 2 250 mm <br />
            Empattement : 1 515 mm <br />
            Poids à sec : 251 kg <br />
            Poids en ordre de marche : 260 kg
          </p>
          <p>
            TRAIN AVANT <br />
            Fourche téléscopique Ø 39 mm, déb : 117 mm <br />
            1 disque Ø 292 mm, étriers 2 pistons <br />
            Roue AV : 100 / 90 - 19 <br />
            Pression : 2.48 bars
          </p>
          <p>
            TRANSMISSION <br />
            Boîte à 5 rapports <br />
            Secondaire par courroie
          </p>
          <p>
            TRAIN ARRIÈRE <br />
            2 amortisseurs latéraux, déb : 61 mm <br />
            1 disque Ø 292 mm, étriers 2 pistons <br />
            Roue AR : 150 / 80 - 16 <br />
            Pression : 2.9 bars
          </p>
          <p>
            MOTEUR <br />
            Bicylindre en V à 45°, 4 temps <br />
            Refroidissement : par air <br />
            1 carbu Ø 40 mm, culbuté <br />
            2 soupapes par cylindre <br />
            883 cc (Alésage * course: 76.2 x 96.8 mm) <br />
            53 ch à 6 000 tr/min <br />
            7,10 mkg à 4 400 tr/min <br />
            Rapport poids / puissance : 4.74 kg/ch <br />
            Rapport poids / couple : 35.35 kg/mkg <br />
            Compression : 9 : 1
          </p>
          <p>Crit'air</p>
          <p className="critair" title="Vignette crit'air">3</p>
        </div>
      </div>
    </div>
    <h1 className="titlebanc">La Titine Puissance</h1>
    <video
      className="titinebancp"
      ref={videobancRef}
      src={Titinebanc}
      loading="lazy"
      onClick={handleClickVideobanc}
    ></video>
    <div>
      <button className="titinepuissance" onClick={handleClickVideobanc}>
        {puissanceEnabled
          ? "Désactiver la Titine puissance"
          : "Activer la Titine puissance"}
      </button>
    </div>
    <h1 className="titlenews">La Titine actualité</h1>
    <div className="card-text-three">
      <h1>Titine en 2024</h1>
      <p>
        À ce jour, Titine embrasse pleinement sa vie de moto. Elle sillonne les
        routes, tel un esprit libre voguant à travers les paysages changeants, les
        jantes dans le vent chantant la mélodie de la liberté. Titine n'est pas
        simplement une machine de métal elle incarne l'esprit de l'aventure, la
        passion de l'inconnu qui appelle au détour de chaque virage.
        <br /><br />
        Sur son chemin, Titine ne voyage pas seule. Elle est accompagnée par une
        fraternité de motos, une confrérie de compagnons de route partageant sa
        soif de découverte et d'exploration. Ensemble, ils forment une troupe
        éclectique, unie par leur amour commun pour la route et les histoires à
        raconter.
        <br /><br />
        Titine continue son périple, son moteur vibrant d'excitation à l'idée des
        aventures à venir, prête à explorer les routes inconnues qui l'attendent.
        Car pour Titine, la vie n'est rien de moins qu'une aventure infinie, et
        chaque tournant de roue est une nouvelle page à écrire dans le livre de
        ses souvenirs.
      </p>
    </div>
    <img className="photolove" src={Titineamour} alt="" loading="lazy"/>
    <Toastifybook/>
    <footer>
      <p className="dev">
        Développé avec ❤️<br />
        par <a href="https://www.linkedin.com/in/victor-hanneton-a87629155/"
          target="_blank"
          rel="noopener noreferrer" >Victor Hanneton</a>
      </p>
      <div className="reseau">
      <div className="contact">
            <a
              href="https://www.facebook.com/profile.php?id=100073521674441"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon className="reseau" icon={faFacebook} />
            </a>
            <a
              href="https://www.instagram.com/titinecherie6/?hl=fr"
              target="_blank"
              rel="noopener noreferrer"
            >
            <FontAwesomeIcon icon={faInstagram} />
          </a>
        </div>
      </div>
      <button className="topp" onClick={scrollToToptwo}>
        Haut de page <FontAwesomeIcon className="chevronup" icon={faChevronUp} />
      </button>
    </footer>
  </div>
  
  );
}

export default HomePage;
