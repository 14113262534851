import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

// Pages
import HomePage from './Page/HomePage';
import TitineBook from './Page/TitineBook';
import TitineLove from './Page/TitineLove';
import TitinePoème from './Page/TitinePoème';
import TitineAccident from './Page/TitineAccident.jsx';

// Component
import Header from './Component/Header';



function App() {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path='/' element={<HomePage />} />
        <Route path='/titinebook' element={<TitineBook />} />
        <Route path='/titinelove' element={<TitineLove />} /> 
        <Route path='/titinepoème' element={<TitinePoème />} />
        <Route path='/titineaccident' element={<TitineAccident />} />
      </Routes>
   </BrowserRouter>
  );
}

export default App;
