import PhotoHome from "../src/Picture/20240412_181356.jpg";
import PhotoT from "../src/Picture/20240424_192822.jpg";
import Titinecopine from "./Picture/Titinecopine.jpeg";
import TitineBanc from "./Picture/Titinebanc.jpg";
import Titinerepas from "./Picture/TitineRepas.jpg";
import TitineLifting from "./Picture/TitineLifting.jpg";
import Titineplot from "./Picture/TitinePlot.jpg";
import Photoreservoir from "./Picture/photoreservoir.jpg";
import Titinedouche from "./Picture/Titinedouche.jpg";
export const slidMoto = [
  {
    id: 1,
    img: PhotoHome,
    title: "Titine qui bronze",
  },
  {
    id: 2,
    img:PhotoT,
    title: "Titine chez le médecin",
  },

  {
    id: 3,
    img:Titinedouche,
    title: "La Titine douche",
  },

  {
    id: 4,
    img: TitineBanc,
    title: "Titine au banc",
  },
 {
    id: 5,
    img: Titinecopine,
    title: "Titine et ses copines",
  },

  {
    id:6,
    img: Titinerepas,
    title: "Titine au bar",
  },

  {
  id:7,
  img: TitineLifting,
  title: " Le Titine Lifting",
},
{
  id:8,
  img: Photoreservoir,
  title: " Le Titine accident",
},

{
id:9,
img:Titineplot,
title:"Le Titine plot",

},





];
