import React, { useEffect } from "react";
import "../Style/TitinePoème.css";

const TitinePoème = () => {
  useEffect(() => {
    const addAnimationToElements = (selector) => {
      const elements = document.querySelectorAll(selector);
      elements.forEach((element) => {
        const randomX = Math.random() * 9000 - 9000;
        const randomY = Math.random() * 9000 - 9000;
        element.style.setProperty("--fromX", `${randomX}%`);
        element.style.setProperty("--fromY", `${randomY}%`);
        element.classList.add("slideInRandom");
      });
    };

    addAnimationToElements(".titrepoème span, .poème span");
  }, []);

  return (
    <div className="poemContainer">
      <h2 className="titrepoème">
        <span>Poème&nbsp;</span>
        <span>pour&nbsp;</span>
        <span>Titine</span>
      </h2>
      <p className="poème">
        <span>"Ma</span> <span>chère</span> <span>et</span> <span>tendre</span> <span>Titine,</span>
        <br />
        <span>Lorsque</span> <span>mes</span> <span>yeux</span> <span>se</span> <span>posent</span> <span>sur</span> <span>toi,</span>
        <br />
        <span>Me</span> <span>reviennent</span> <span className="toujours">toujours</span> <span>les</span> <span>souvenirs</span><br /> <span>de</span> <span>notre</span> <span>première</span> <span>fois.</span>
        <br />
        <br />
        <span>Ce</span> <span>n'est</span> <span>pas</span> <span>seulement</span> <span>mon</span> <span>regard</span> <span>qui</span> <span>s'illumine,</span>
        <br />
        <span>Mais</span> <span>mon</span> <span>corps</span> <span>tout</span> <span>entier</span> <span>qui</span> <span>s'éprend</span>
        <br />
        <span>Au</span> <span>toucher</span> <span>de</span> <span>ton</span> <span>unique</span> <span>et</span> <span>envoûtant</span> <span>lustre.</span>
        <br />
        <span>Et</span> <span>si</span> <span className="je">je</span> <span>parle</span> <span>toujours</span> <span>de</span> <span>toi</span> <span>avec</span> <span>passion,</span>
        <br />
        <span>Pardonne-moi</span> <span>de</span> <span>parfois</span> <span>être</span> <span>un</span> <span>peu</span> <span>rustre,</span>
        <br />
       <span>Mais</span> <span>je</span> <span className="suis">suis</span> <span>ainsi</span><span>.</span><span>.</span><span>.</span>
        <br />
        <br />
        <span>Surtout</span> <span>quand,</span> <span>de</span> <span>nos</span> <span>ébats</span> <span>à</span> <span>deux,</span>
        <br />
        <span>Je</span> <span>ne</span> <span>peux</span> <span>m'empêcher</span> <span>de</span> <span>penser</span> <span>trois,</span>
        <br />
        <span>Bien</span> <span>que</span> <span>tu</span> <span>sois</span> <span>celle</span> <span>dont</span> <span>je</span> <span>suis </span> <span className="amoureux">amoureux</span>
        <br />
        <span>Tendre</span> <span>n'est</span> <span>pas</span> <span>la</span> <span>vie,</span> <span>chacun</span> <span>porte</span> <span>sa</span> <span>croix.</span>
        <br />
        <br />
        <span>Mais</span> <span>laisse</span>
        <span>-</span>
        <span>moi</span> <span>te</span> <span>jurer</span> <span>solennellement,</span>
        <br />
        <span>Sauvage</span> <span>et</span> <span>rebelle</span> <span>Titine</span>
        <br />
        <span>Et</span> <span>qu'en</span> <span>Enfer</span> <span>je</span> <span>brûle,</span> <span>si</span> <span>à</span> <span>toi</span> <span>je</span> <span>mens,</span>
        <br />
        <span>Que</span> <span>ta</span> <span>seule</span> <span>caresse</span> <span>m'est</span> <span>source</span> <span className="de">de</span> <span>plaisir</span> <br />
        <span>Je</span> <span> suis</span> <span>tout</span> <span>à</span> <span>toi,</span> <br />
        <span>Aujourd'hui,</span> <span>demain</span> <span>et</span> <span>pour</span> <span>toujours,</span>
        <br />
        <span>Ma</span> <span>douce</span> <span>et</span> <span>belle</span> <span className="titine">Titine</span> <span>Chérie.</span>
        <br />
        <br />
        <span>C'est</span> <span>toi,</span> <span>c'est</span> <span>moi,</span> <span>sur</span> <span>ces</span> <span>routes</span> <span>infinies</span>
        <br />
        <span>L'ivresse</span> <span>complète</span> <span>de</span> <span>mon</span> <span>corps</span> <span>te</span> <span>chevauchant</span>
        <br />
        <span>Toi,</span> <span className="et">et</span> <span>tes</span> <span>rondeurs</span> <span>que</span> <span>mon</span> <span>âme</span> <span>personnifie</span>
        <br />
        <span className="De">De</span> <span>la</span> <span>nuit</span> <span>tombée</span> <span>jusqu'au</span> <span>soleil</span> <span>levant.</span>
        
        <br />
        <br />
        <span className="s">Sarah<span></span><span><span></span></span>.<span className="v">Victor</span></span>
      </p>
    </div>
  );
};

export default TitinePoème;
