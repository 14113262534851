import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { slidMoto } from "../PictureData";
import "react-responsive-carousel/lib/styles/carousel.min.css"; 
import { Carousel } from 'react-responsive-carousel'; 
import "../Style/Slider.css";

const Slider = () => {
  const [currentSlide, setCurrentSlide] = useState(0);

  const handleChange = (index) => {
    setCurrentSlide(index); 
  };

  const handlePrevClick = () => {
    if (currentSlide > 0) {
      setCurrentSlide(currentSlide - 1); 
    } else {
      setCurrentSlide(slidMoto.length - 1); 
    }
  };

  const handleNextClick = () => {
    if (currentSlide < slidMoto.length - 1) {
      setCurrentSlide(currentSlide + 1); 
    } else {
      setCurrentSlide(0); 
    }
  };

  return (
    <div className="slider-container">
      <div className="arrow left" onClick={handlePrevClick}>
      <FontAwesomeIcon icon={faAngleLeft} />          </div>
      <Carousel
        showThumbs={false}
        showStatus={false}
        showIndicators={false}
        emulateTouch
        onChange={handleChange}
        selectedItem={currentSlide}
      >
        {slidMoto.map((item, index) => (
          <div key={index} className="slide">
            <div className="imgContainer">
              <img src={item.img} alt={`Slide ${index}`} />
            </div>
            <div className="Title">{item.title}</div>
            <div className="infocontainer">
            </div>
          </div>
          
        ))}
      </Carousel>
      <div className="arrow right" onClick={handleNextClick}>
      <FontAwesomeIcon icon={faAngleRight} />     </div>
    </div>
  );
};

export default Slider;
