import React, { useState, useRef } from "react";
import "../Style/TitineBook.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import FlipPage from "react-flip-page";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast, Bounce } from "react-toastify";

import picturebook from "../Picture/photobook.jpg";
import titinecompteur from "../Picture/titinecompteur.jpg";
import echapement from "../Picture/echappement.jpg";
import Titinecloche from "../Picture/Cloche.jpg";
import Plaque from "../Picture/Plaque.jpg";
import filtre from "../Picture/filter.jpg";
import selle from "../Picture/Selle.jpg";
import cle from "../Picture/key.jpg";
import guidon from "../Picture/Guidon.jpg";
import titinebook from "../Picture/Titine-book.jpg";

const TitineBook = () => {
  const [currentPage, setCurrentPage] = useState(0);
  const flipPageRef = useRef(null);
  const totalPages = 3; // Nombre total de spreads (double pages)
  
  const navigate = useNavigate();

  const handlePageChange = (pageIndex) => {
    setCurrentPage(pageIndex);

    // Vérifie si c'est la dernière page
    if (pageIndex === totalPages - 1) {
      toast("Cliquer ici ❤️❤️", {
        position: "bottom-right",
        autoClose: 60000, // 1 minute
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: "custom-toastbook",
        transition: Bounce,
        onClick: () => navigate("/titinelove"), // Redirection lorsqu'on clique sur la notification
      });
    }
  };

  const handleButtonClick = (direction) => {
    if (flipPageRef.current) {
      if (direction === "left" && currentPage > 0) {
        flipPageRef.current.gotoPreviousPage();
      } else if (direction === "right" && currentPage < totalPages - 1) {
        flipPageRef.current.gotoNextPage();
      }
    }
  };

  return (
    <div className="demopage">
      <ToastContainer />
      <FlipPage
        className="flip-page"
        ref={flipPageRef}
        orientation="horizontal"
        responsive
        animationDuration={1500}
        flipOnTouch={false} 
        onPageChange={handlePageChange}
        onPageChangeAnimationEnd={(pageIndex) => console.log("Animation terminée pour la page :", pageIndex)}
        showTouchHint={false} 
        showSwipeHint={false}
        disableSwipe={false}
      >
      
        <div className="demoPage">
          <div className="white-side">
            <div className="border-black">
              <div className="text-intro">
              <h1>Titine Book 2024</h1>
              <h2>Introduction</h2>
              <p>
                Titine Book est une description détaillée de notre chère Titine. <br />
                À travers ces pages, vous découvrirez les subtilités et l'élégance de Titine. <br />
                Chaque photo, chaque description, et chaque anecdote ont été soigneusement sélectionnées <br />
                pour célébrer la beauté et le charme intemporels de Titine.
              </p>
              </div>
            </div>
            <img className="picturebook" src={picturebook} alt="Photobook" loading="lazy" />
            <p className="picturename">
              Photographe <br /> Victor Hanneton
            </p>
            <p className="page-number-one">1 / 6</p>
          </div>
          <div className="black-side">
            <h2 className="kmbook">Le kilométrage de Titine</h2>
            <img className="titinecompteur" src={titinecompteur} alt="Compteur" loading="lazy" />
            <p className="km-desription">
              Titine a parcouru 32 000 km en 19 ans d'existence et a eu quatre
              propriétaires différents durant cette période. Au fil des années,
              chaque kilomètre parcouru par Titine raconte une histoire. De
              longues routes sinueuses aux courts trajets urbains, elle a su
              démontrer une fiabilité et une endurance exceptionnelles.
            </p>
            <h2 className="title-echappement">L'échappement de Titine</h2>
            <p className="echappement-description">
              L'échappement est un screamin <br /> Eagle 2 chromé, une pièce
              haut de <br /> gamme qui allie performance et <br />
              esthétique. Cet échappement <br /> améliore le flux des gaz <br />
              d'échappement, augmentant ainsi la puissance et l'efficacité du moteur.
            </p>
            <img className="titineechappement" src={echapement} alt="Échappement" loading="lazy" />
            <p className="number-two">2 / 6</p>
          </div>
        </div>
        {/* Deuxième page */}
        <div className="demoPage">
          <div className="white-side">
            <div className="container-cloche">
              <h2 className="guardian-bell">La Guardian Bell de Titine</h2>
              <p className="cloche-text">
                Un cadeau offert par une amie de Titine pour la protéger
                de la casse et du mauvais sort que la route pourrait lui
                réserver. Ce cadeau a sauvé la vie de la douce Titine
                le jour de son accident.
                <br />
                <br />
                La Guardian Bell doit être fixée à la partie inférieure
                du cadre de la moto et ne doit ni être achetée ni fixée
                par le propriétaire de la moto.
              </p>
              <img className="titine-cloche" src={Titinecloche} alt="Guardian Bell" loading="lazy" />
            </div>
            <div className="container-plaque">
              <h2 className="title-plaque">La plaque de Titine</h2>
              <p className="plaque-text">
                Titine arbore une magnifique plaque, <br />
                non homologuée, ornée de quatre clous chromés
                <br />
                et de lettres noires. <br /> Mais après tout, qui a besoin de
                conformité quand <br />
                on a une telle élégance ?<br /> <br />
                Cette plaque unique ne passe jamais inaperçue <br />
                et attire tous les regards, <br /> offrant une touche de
                caractère et de distinction à Titine. <br /> Elle est la
                preuve que parfois, <br /> il faut savoir sortir des sentiers
                battus <br /> pour réellement briller.
              </p>
              <img className="titine-plaque" src={Plaque} alt="Plaque"  loading="lazy"/>
            </div>
            <p className="page-number-white-plaque">3 / 6</p>
          </div>
          <div className="black-side">
            <div className="container-filtre">
              <h2 className="title-filtre">Le filtre à air de Titine</h2>
              <p className="filtre-text">
                Titine est équipée d'un filtre à air Screamin' <br />
                Eagle Stage 1, ce qui permet une meilleure absorption de l'air,
                améliorant ainsi l'alimentation du moteur. <br />
                Grâce à cette optimisation, le moteur fonctionne de manière plus
                efficace, offrant des performances optimales.
              </p>
              <img className="titine-filtre" src={filtre} alt="Filtre" loading="lazy"/>
            </div>
            <h2 className="title-selle">La Selle de Titine</h2>
            <p className="text-selle">
              Titine est équipée d'une selle confort duo, accompagnée <br />
              d'un sissy bar, <br /> offrant un excellent soutien <br />
              aux passagers <br /> qui auront le privilège de se faire satelliser
              par la douce Titine.
            </p>
            <img className="titine-selle" src={selle} alt="Selle" loading="lazy"/>
            <p className="page-number-black-four">4 / 6</p>
          </div>
        </div>
        {/* Troisième page */}
        <div className="demoPage">
          <div className="white-side">
            <h2 className="title-key">La clé de Titine</h2>
            <img className="titine-key" src={cle} alt="Clé" loading="lazy"/>
            <p className="text-key">
              Titine est équipée d'une clé avec un transpondeur qui permet de
              désactiver son alarme. Sans ce transpondeur, il est impossible de
              démarrer Titine, car le système bloque le démarreur. Combiné avec
              un verrouillage de la colonne de direction, ces deux éléments
              créent des sécurités pour protéger Titine du vol.
            </p>
            <h2 className="title-guidon"> Le guidon de Titine</h2>
            <img className="titine-guidon" src={guidon} alt="Guidon" loading="lazy"/>
            <p className="text-guidon">
              Suite à son accident, l'ancien guidon en Z de Titine a été plié. Il a été remplacé par un guidon plus haut, ce qui évitera un nouvel impact du guidon dans le réservoir en cas d'accident et offre une meilleure posture de conduite.
            </p>
            <p className="page-number-white-five">5 / 6</p>
          </div>
          <div className="black-side">
            <h2 className="title-book-titine">Titine Chérie</h2>
            <img className="titine-book" src={titinebook} alt="Titine Book" loading="lazy"/>
            <p className="date-picture">13/07/2024</p>
            <p className="page-number-black-six">6 / 6</p>
          </div>
        </div>
      </FlipPage>
      

   
      <button
        className={`buttonicon right ${currentPage < totalPages - 1 ? "active" : ""}`}
        onClick={() => handleButtonClick("right")}
      >
        <FontAwesomeIcon className="icon" icon={faAngleRight} />
      </button>

      <button
        className={`buttonicon left ${currentPage > 0 ? "active" : ""}`}
        onClick={() => handleButtonClick("left")}
      >
        <FontAwesomeIcon className="icon" icon={faAngleLeft} />
      </button>
    </div>
  );
};

export default TitineBook;
