import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Assurez-vous d'importer ce fichier CSS pour les styles de la notification
import "../Style/Toasty.css";

const ToastyNotification = () => {
  const navigate = useNavigate();
  const [notificationShown, setNotificationShown] = useState(false);

  // Fonction pour déclencher la notification
  const notify = () => {
    if (!notificationShown) {
      toast("Cliquer ici ✒️📝", {
        position: "bottom-right",
        autoClose: 300000, 
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: "custom-toast-two",
        transition: Bounce,
        onClick: () => navigate("/titinepoème"), // Redirection lorsqu'on clique sur la notification
      });
      setNotificationShown(true);
    }
  };

  // Ajout d'un déclencheur basé sur un timer pour afficher la notification après 55 secondes
  useEffect(() => {
    const timer = setTimeout(() => {
      notify(); // Affiche la notification après 55 secondes
    }, 47000); // 55 000 ms = 55 secondes

    // Nettoyage du timeout lors du démontage du composant
    return () => clearTimeout(timer);
  }, [notificationShown]); // Ajout de notificationShown dans les dépendances

  return (
    <div>
      <ToastContainer
        className="custom-toast-container-two"
        position="bottom-right"
        autoClose={60000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
    </div>
  );
};

export default ToastyNotification;
