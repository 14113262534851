// React
import React, { useState, useRef, useEffect } from "react";
import "../Style/TitineLove.css";
import "intersection-observer";
import Toastify from "../Component/Toastify";

//Picture
import PhotoTitineLove from "../Picture/PhotoLoveTitine.png";
import Chanson from "../Picture/careless.mp3";
import TitineMariage from "../Picture/photomariage.png";
import couplephoto from "../Picture/couple.png";
import photosaintvalentin from "../Picture/photosaintvalentin.png";
import Titineautoroute from "../Picture/TitineAutoroute.mp4";
import Titineguidonnage from "../Picture/Titineguidonnage.mp4";
import Titinecalin from "../Picture/Titine-calin.png";
import Titinevirage from "../Picture/Titinevirage.mp4";
import Titinemain from "../Picture/Titinemain.png";
import Titinekm from "../Picture/Titinekm.jpg";
import Titineforever from "../Picture/Titineforever.png";
import Titinetshirtone from "../Picture/Titinetshirt1.jpg";
import Titinetshirt from "../Picture/TitineTshirt.jpg";
import titinebob from "../Picture/titinebob.jpg"
import titinebobtwo from "../Picture/titinebobotwo.jpg";


//Icons
import { faFacebook, faInstagram } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { faChevronUp } from '@fortawesome/free-solid-svg-icons';

const Titinelove = () => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const topReftwo = useRef(null);
  const [km, setKm] = useState(11700);
  const [playIcons, setPlayIcons] = useState({
    video: true,
    videotwo: true,
    videothree: true,
  });

  useEffect(() => {
    const elements = document.querySelectorAll(".slide-element");
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("slideIn");
          observer.unobserve(entry.target);
        }
        if (entry.isIntersecting) {
          entry.target.classList.add("show");
          observer.unobserve(entry.target);
        }
      });
    });

    elements.forEach((element) => {
      observer.observe(element);
    });

    return () => {
      if (observer) {
        observer.disconnect();
      }
    };
  }, []);
  const scrollToToptwo = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };


  useEffect(() => {
    // Vérifie si le kilométrage est inférieur à 12225
    if (km < 12225) {
      // Calcule l'incrément en fonction de la différence entre 12225 et 11700 sur une durée de 15 secondes (15000 millisecondes)
      const difference = 12225 - 11700;
      // Définit l'incrément à 1 pour que le compteur augmente d'un chiffre à chaque itération
      const increment = 1;
      // Crée un intervalle pour mettre à jour le kilométrage
      const interval = setInterval(() => {
        setKm((prevKm) => {
          // Si le kilométrage actuel + l'incrément dépasse 12225, on arrête l'intervalle et retourne 12225
          if (prevKm + increment >= 12225) {
            clearInterval(interval);
            return 12225;
          }
          // Sinon, on ajoute l'incrément au kilométrage actuel
          return prevKm + increment;
        });
      }, 1000); // Définit la fréquence de mise à jour à 10 millisecondes pour obtenir une transition fluide
      // Retourne une fonction de nettoyage pour arrêter l'intervalle lorsque le composant est démonté ou que le kilométrage atteint 12225
      return () => clearInterval(interval);
    }
  }, [km]); // Déclenche l'effet à chaque changement de km

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const playPause = (id) => {
    const video = document.getElementById(id);
    if (video.paused) {
      video.play();
      setPlayIcons((prev) => ({ ...prev, [id]: false }));
    } else {
      video.pause();
      setPlayIcons((prev) => ({ ...prev, [id]: true }));
    }
  };

  useEffect(() => {
    const wordsElements = document.querySelectorAll(".amour-chemin span");

    const showWords = (index, words) => {
      if (index < words.length) {
        words[index].classList.add("show");
        setTimeout(() => {
          showWords(index + 1, words);
        }, 500);
      }
    };

    const wordsObserver = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          showWords(0, wordsElements);
          wordsObserver.unobserve(entry.target);
        }
      });
    });

    wordsObserver.observe(document.querySelector(".amour-chemin"));

    return () => {
      if (wordsObserver) {
        wordsObserver.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    const heartsContainer = document.querySelector(".hearts-container");

    const createHeart = () => {
      const heart = document.createElement("span");
      heart.classList.add("heart");
      heart.innerText = "❤️";
      heart.style.left = `${Math.random() * 100}vw`;
      heartsContainer.appendChild(heart);

      setTimeout(() => {
        heart.remove();
      }, 90000); // Temps pour que le cœur disparaisse
    };

    const heartInterval = setInterval(createHeart, 800); // Intervalle de création des cœurs

    return () => clearInterval(heartInterval);
  }, []);


 
  window.onbeforeunload = function () {
    window.scrollTo(0, 0);
  };




  return (
    
   
    <div  ref={topReftwo}>

   <body>
    
   
        <h1 className="titrelove">La Titine Rencontre</h1>
        <div className="buttonsong-container slide-element slide-element slide-from-left ">
          <button
            className="buttonsong slide-element slide-from-left "
            onClick={togglePlay}>
            {isPlaying ? "  ❤️ Titine Love  ❤️" : "Activer l'amour"}
          </button>
        </div>
        <audio ref={audioRef} src={Chanson}></audio>
        <img className="titinelove" src={PhotoTitineLove} loading="lazy" />
        <div className="card-text-date">
          <h1>Samedi 19 Décembre 2020 / 15h11</h1>
          <p>
            C'est ce jour-là que j'ai fait la connaissance de Titine pour la
            première fois à Marly, une petite ville près de Valenciennes. Elle
            était là, sagement stationnée devant une résidence, ses jantes
            étincelantes captant les rayons du soleil, réchauffant mon coeur
            ainsi que l'atmosphère de leur éclat. Dès que nos regards se sont
            croisés, j'ai immédiatement su que c'était elle, et aucune autre,
            qui me conviendrait.
          </p>
        </div>
        <img
          className="titine-couple slide-element slide-from-right"
          src={couplephoto} loading="lazy"
        />
        <h1 className="titre-couple slide-element slide-from-right">
          Le Titine Couple
        </h1>
        <div className="card-couple slide-element slide-from-right">
          <h1>Samedi 9 Octobre 2021</h1>
          <p>
            Après avoir pris le temps de mieux nous connaître et de traverser en
            compagnie de Titine un hiver glacial et un été brûlant, sans que la
            chaleur de mon cœur pour elle ne change, j'ai pris l'initiative
            d'officialiser notre relation aux yeux de tous. Avec du recul, cela
            s'est avéré être l'une des meilleures décisions de ma vie, ce qui a
            particulièrement ému la douce et belle Titine.
          </p>
        </div>
        <img
          className="titine-saint-valentin slide-element slide-from-right"
          src={photosaintvalentin}
          loading="lazy"
        />
        <h1 className="titre-saint-valentin slide-element slide-from-right">
          La Titine Saint Valentin
        </h1>
        <div className="card-saint-valentin slide-element slide-from-right">
          <h1>Vendredi 14 Février 2022</h1>
          <p>
            Pour notre première Saint-Valentin, j'ai créé un magnifique
            PowerPoint sur fond de "Que je t'aime" de Johnny Hallyday. À travers
            cette présentation, je voulais lui témoigner ma gratitude et ma
            chance pour sa présence dans ma vie depuis plus d'un an, ainsi que
            tout mon amour et mon dévouement pour elle.
          </p>
        </div>
        <img
          className="photo-mariage slide-element slide-from-left"
          src={TitineMariage}
          loading="lazy"
        />
        <h1 className="titre-mariage slide-element slide-from-left">
          Le Titine Mariage
        </h1>
        <div className="card-mariage slide-element slide-from-left">
          <h1>Mercredi 14 Février 2024</h1>
          <p>
            Une date inoubliable, cela fait maintenant déjà plus de trois ans
            que Titine est entrée dans ma vie. Trois ans d'émotions multiples
            que seul un être aimé peut vous faire vivre. Nous avons partagé des
            moments de joie, de tristesse, de rires et de complicité,
            construisant des souvenirs inestimables. Pour la remercier de tout
            ce qu'elle m'apporte et de sa présence constante à mes côtés, je me
            suis agenouillé le jour de la Saint-Valentin pour lui demander sa
            main, espérant ainsi débuter un nouveau chapitre de notre vie
            commune, rempli d'amour et de bonheur.
          </p>
        </div>
        <h1 className="Titine-aventure slide-element slide-from-left">
          La Titine Aventure
        </h1>

        <div className="video-container slide-element slide-from-left">
          <div className="video-wrapper ">
            {playIcons.video && (
              <div className="play-icon" onClick={() => playPause("video")}>
                <FontAwesomeIcon icon={faPlay} />
              </div>
            )}
            <video
              id="video"
              className="video-aventure "
              src={Titineautoroute}
              controls={false}
              onClick={() => playPause("video")}
              loading="lazy"
            />
          </div>

          <div className="video-wrapper">
            {playIcons.videotwo && (
              <div className="play-icon" onClick={() => playPause("videotwo")}>
                <FontAwesomeIcon icon={faPlay} />
              </div>
            )}
            <video
              id="videotwo"
              className="video-aventure-deux"
              src={Titinevirage}
              controls={false}
              onClick={() => playPause("videotwo")}
              loading="lazy"
            />
          </div>

          <div className="video-wrapper">
            {playIcons.videothree && (
              <div
                className="play-icon"
                onClick={() => playPause("videothree")}
              >
                <FontAwesomeIcon icon={faPlay} />
              </div>
            )}
            <video
              id="videothree"
              className="video-aventure-trois"
              src={Titineguidonnage}
              controls={false}
              onClick={() => playPause("videothree")}
              loading="lazy"
            />
          </div>
        </div>

        <div className="image-container ">
          <h1 className="titre-calin slide-element slide-from-right">
            Le Titine Calin
          </h1>
          <img
            className="titine-calin slide-element slide-from-right"
            src={Titinecalin}
          />
        </div>
        <div className="image-container-two">
          <h1 className="km-amour slide-element slide-from-left">
            {Math.round(km)} kms d'amour avec toi
          </h1>

          <img
            className="titine-km slide-element slide-from-left "
            src={Titinekm}
          />
          <h1 className="titre-love slide-element slide-from-left">
            
          </h1>
          <h1 className="amour-chemin slide-element slide-from-left">
            <span>" </span>
            <span>Je </span>
            <span>ne </span>
            <span>sais </span>
            <span>où </span>
            <span>va </span>
            <span>mon </span>
            <span>chemin</span>
            <span>,</span>
            <br />
            <span>mais </span>
            <span>je </span>
            <span>marche </span>
            <span>mieux </span>
            <br />
            <span>quand </span>
            <span>ma </span>
            <span>main </span>
            <span>serre </span>
            <span>la </span>
            <span>tienne</span>
            <span>.</span>
            <span>.</span>
            <span>.</span>
            <span> "</span>
          </h1>
          <img
            className="titine-main slide-element slide-from-left "
            src={Titinemain}
            loading="lazy"
          />
        </div>
        <div className="Container-parole">
  <h1 className="Onira slide-element slide-from-right ">
    "Titine, on ira où tu voudras, quand tu voudras"
  </h1>
  <div className="Titine-parole slide-element slide-from-right ">
    <h3>L'été indien de Titine</h3>
    <p>
      Oh Titine,
      <br />
      Tu sais, je n'ai jamais été aussi heureux que ce matin-là
      <br />
      Nous marchions sur une plage un peu comme celle-ci <br />
      C'était l'automne, un automne où il faisait beau <br />
      Une saison qui n'existe que dans le Nord de l'Amérique <br />
      Là-bas on l'appelle l'été indien <br />
      Mais c'était tout simplement le nôtre <br />
      Avec ta robe longue <br />
      Tu ressemblais à une aquarelle de Marie Laurencin <br />
      Et j'me souviens <br />
      J'me souviens très bien de c'que j't'ai dit ce matin-là <br />
      Il y a un an, y'a un siècle, y a une éternité <br />
      On ira où tu voudras, quand tu voudras <br />
      Et l'on s'aimera encore, lorsque l'amour sera mort <br />
      Toute la vie sera pareille à ce matin <br />
      Aux couleurs de l'été indien <br />
      Aujourd'hui je suis très loin de ce matin d'automne <br />
      Mais c'est comme si j'y étais <br />
    </p>
    <p>1</p>
  
  </div>
  <div className="titine-parole-two slide-element slide-from-right">
    <p>
      Je pense à toi <br />
      Où es tu <br />
      Que fais-tu <br />
      Est-ce que j'existe encore pour toi <br />
      Je regarde cette vague qui n'atteindra jamais la lune <br />
      Tu vois, comme elle je reviens en arrière <br />
      Comme elle, je me couche sur le sable et j'me souviens <br />
      Je me souviens des marées hautes <br />
      Du soleil et du bonheur qui passaient sur la mer <br />
      Il y a une éternité, un siècle, il y a un an <br />
      On ira où tu voudras, quand tu voudras <br />
      Et l'on s'aimera encore, <br /> lorsque l'amour sera mort <br />
      Toute la vie sera pareille à ce matin <br />
      Aux couleurs de l'été indien <br />
      On ira où tu voudras, <br /> quand tu voudras <br />
      Et l'on s'aimera encore, <br /> lorsque l'amour sera mort <br />
      Toute la vie sera pareille à ce matin. <br />
    </p>
    <p className="number-two-indien">2</p>
  </div>
</div>

  <div className="container-forever slide-element slide-from-left">
          <h1>❤️ Titine Forever dans mon coeur ❤️ </h1>
          <img className="titineforever" src={Titineforever} loading="lazy"/>
  <div ref={topReftwo}>
    <div className="hearts-container">  
         </div>
         </div>
        </div>
        <h1 className="merch slide-element slide-from-left">Titine Merch</h1>
        <div className="container-tshirt">
  <h1  className="slide-element slide-from-right"> Le Titine T-shirt</h1>
  <div className="image-containerfour">
    <img className="slide-element slide-from-left" src={Titinetshirtone} alt="titine-t-shirt" loading="lazy"/>  
      <img className="slide-element slide-from-right" src={Titinetshirt} alt="titine-t-shirt" loading="lazy"/>
  </div>
</div>
<div className="container-bob ">
  <h1 className="slide-element slide-from-left"> Le Titine Bob</h1>
  <div className="image-containerfour">
    <img className="slide-element slide-from-left" src={titinebob} alt="titine-bob" loading="lazy"/>  
      <img className="slide-element slide-from-right" src={titinebobtwo} alt="titine-bob" loading="lazy"/>
  </div>
</div>
<Toastify/>
        <footer className="footertwo">
          <p className="dev">
            Développé avec ❤️   <br /> par{" "}
            <a href="https://www.linkedin.com/in/victor-hanneton-a87629155/"
            target="_blank"
              rel="noopener noreferrer"> Victor Hanneton
            </a>
          </p>

          <div className="contact">
            <a
              href="https://www.facebook.com/profile.php?id=100073521674441"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon className="reseau" icon={faFacebook} />
            </a>
            <a
              href="https://www.instagram.com/titinecherie6/?hl=fr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon  className="reseau" icon={faInstagram} />
            </a>
          </div>
          <p className="top" onClick={scrollToToptwo}>
          Haut de page
          <FontAwesomeIcon className="chevronup" icon={faChevronUp} />
        </p>
        </footer>
        
          
        </body>

    </div>
   
  );
};

export default Titinelove;
