import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../Style/Header.css';
import Preview from '../Picture/Titineland.png';

const Header = () => {

  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-MCRK9W5K');
    `;
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <header>
      {}
      <noscript>
        <iframe src="https://www.googletagmanager.com/ns.html?id=GTM-MCRK9W5K"
                height="0" width="0" style={{ display: 'none', visibility: 'hidden' }}></iframe>
      </noscript>
      {}
      <Link to="/">
        <img className="slogan" src={Preview} alt="Titine Preview" />
      </Link>
      <div className="page">
        <Link to="/"><p>Titine Accueil</p></Link>
        <Link to="/TitineBook"><p>Titine Book</p></Link>
        <Link to="/TitineLove"><p>Titine Love</p></Link>
        <Link to="/TitineAccident"><p>Titine Accident</p></Link>
        <Link to="/TitinePoème"><p>Titine Poème</p></Link>
      </div>
    </header>
  );
};

export default Header;
