import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast, Bounce } from "react-toastify";
import "react-toastify/dist/ReactToastify.css"; // Assurez-vous d'importer ce fichier CSS pour les styles de la notification
import "../Style/Toasty.css";

const ToastyNotification = () => {
  const navigate = useNavigate();
  const [notificationShown, setNotificationShown] = useState(false);

  // Fonction pour déclencher la notification
  const notify = () => {
    if (!notificationShown) {
      toast("Cliquer ici 📖📚", {
        position: "bottom-right",
        autoClose: 60000, // 1 minute (60 000 ms)
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        className: "custom-toast", 
        transition: Bounce,
        onClick: () => navigate("/Titinebook"), // Redirection lorsqu'on clique sur la notification
      });
      setNotificationShown(true);
    }
  };

  // Ajout d'un événement de défilement pour déclencher une notification
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >=
        document.documentElement.scrollHeight
      ) {
        notify(); // Affiche la notification lorsqu'on atteint le bas de la page
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Nettoyage de l'événement lors du démontage du composant
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [notificationShown]); // Ajout de notificationShown dans les dépendances

  return (
    <div>
      <ToastContainer
        className="custom-toast-container"
        position="bottom-right"
        autoClose={60000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        transition={Bounce}
      />
    </div>




  );
};

export default ToastyNotification;
