import React, { useRef, useState, useEffect } from "react";
import "../Style/TitineAccident.css";
import videopluie from "../Picture/videopluie.mp4";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMusic, faPlay } from "@fortawesome/free-solid-svg-icons"; // Importation de l'icône spécifique
import music from '../Picture/list.mp3';
import Toastifybookaccident from "../Component/Toastifybookaccident";


const TitineAccident = () => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);

  const togglePlay = () => {
    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  useEffect(() => {
    const lines = document.querySelectorAll(".contente p");
    lines.forEach((line, index) => {
      line.classList.add("line", `delay-${index + 1}`);
    });
  }, []);

  return (
    <div>
      <bodyaccident>
      <Toastifybookaccident/>
      <div className="background-video-container">
        <video autoPlay loop muted className="background-video" loading="lazy">
       

          <source src={videopluie} type="video/mp4" loading="lazy" />
        </video>

        <div className="contente">
  <FontAwesomeIcon icon={faPlay} className="custom-icon large-icon" onClick={togglePlay} />
  <FontAwesomeIcon icon={faMusic} className={`custom-icon large-icon ${isPlaying ? "blinkeing" : ""}`} onClick={togglePlay} />



          <audio ref={audioRef} src={music}></audio>
          <h1 className="line-delay-1">L'accident de Titine</h1>
<br />
<p className="line delay-2">Le <strong className="colorbold">drame</strong> arriva le mercredi 3 janvier 2024 à 12h05,</p>
<p className="line delay-3">Avenue du Barœul à Mons-en-Barœul. </p>
<p className="line delay-4">C'était une journée particulièrement <strong className="colorbold">morose,</strong> <br /></p>
<p className="line delay-5">un véritable déluge<strong className="colorbold"> s'abattit</strong> sur Titine. <br /></p>
<p className="line delay-6">Une pluie torrentielle, un vent <strong className="colorbold">déchaîné,</strong><br /></p>
<p className="line delay-7">et une route devenue glissante comme une patinoire<br /></p>
<p className="line delay-8"> <strong className="colorbold">menaçaient</strong> la douce Titine. <br /></p>
<p className="line delay-9">Sur une longue ligne droite, à une vitesse quelque peu inadaptée, <br /></p>
<p className="line delay-10">une voiture devant Titine freina soudainement et <strong className="colorbold">brutalement.</strong> <br /></p>
<p className="line delay-11">Les freins de Titine furent sollicités beaucoup trop fortement, <br /></p>
<p className="line delay-12">et en l'absence d'ABS et d'une route bien trop humide, <br /></p>
<p className="line delay-13">la <strong className="colorbold">tragédie</strong> survint. <br /></p>
<p className="line delay-14">La roue arrière de Titine se bloqua <br /></p>
<p className="line delay-15">et Titine perdit l'équilibre et <strong className="colorbold">chuta</strong> sur le côté gauche, <br /></p>
<p className="line delay-16">tombant à plus de 50 km/h sur plusieurs mètres. <br /></p>
<p className="line delay-17">Le <strong className="colorbold">choc</strong> avec le sol fut des plus <strong className="colorbold">violents</strong>, <br /></p>
<p className="line delay-18">et le moteur de Titine se <strong className="colorbold">coupa</strong> net. <br /></p>
<p className="line delay-19">Lors de la <strong className="colorbold">chute</strong>, <br /></p>
<p className="line delay-20">les commandes avancées servirent de crash barres <br /></p>
<p className="line delay-21">et absorbèrent une grande partie de l'impact et des <strong className="colorbold">dégâts,</strong> <br /></p>
<p className="line delay-22">mais pas totalement. <br /></p>
<p className="line delay-23">En <strong className="colorbold">tombant</strong>, le guidon de la tendre Titine <strong className="colorbold">heurta</strong> son réservoir, <br /></p>
<p className="line delay-24">créant un <strong className="colorbold">impact</strong> de quelques centimètres de profondeur. <br /></p>
<p className="line delay-25">La partie gauche du guidon fut pliée lors de <strong className="colorbold">l'impact</strong>, <br /></p>
<p className="line delay-26">le rétroviseur et le clignotant gauche furent rayés, <br /></p>
<p className="line delay-27">le bout du levier d'embrayage gauche <strong className="colorbold">coupé</strong> net, <br /></p>
<p className="line delay-28">et la poignée du guidon gauche <strong className="colorbold">arrachée</strong> en son milieu. <br /></p>
<p className="line delay-29">Le repose-pied et le sélecteur de vitesse eurent encore moins de chance <br /></p>
<p className="line delay-30">et furent <strong className="colorbold">totalement</strong> pliés.</p>
<p className="line delay-31">Une gentille dame, venant d'arriver sur place, <br /></p>
<p className="line delay-32">s'arrêta et sortit de sa voiture pour proposer son aide <br /></p>
<p className="line delay-33">à la tendre Titine a fin de la relever. <br /></p>
<p className="line delay-34">Mais Titine se releva seule, <strong className="colorbold">forte</strong> et <strong className="colorbold">vaillante,</strong> <br /></p>
<p className="line delay-35">inflexible et <strong className="colorbold">déterminée</strong> face aux défis de la route <br /></p>
<p className="line delay-36">Lorsque la douce et <strong className="colorbold">robuste</strong> Titine se releva, <br /></p>
<p className="line delay-37">de l'essence s'était écoulée sur son réservoir <br /></p>
<p className="line delay-38">et s'était répandue sur le sol. <br /></p>
<p className="line delay-39">Malgré tout Titine redémarra instantanément, <br /></p>
<p className="line delay-40">passa la première vitesse et reprit la route, <br /></p>
<p className="line delay-41">quelque peu <strong className="colorbold">avisée.</strong></p>
<p className="line delay-42">Titine rentra jusqu'à ses quartiers <br /></p>
<p className="line delay-43">et prit sa journée pour se remettre de toutes ces <strong className="colorbold">émotions.</strong> <br /></p>
<p className="line delay-44">Elle contacta son médecin pour prévoir une révision complète <br /></p>
<p className="line delay-45">ainsi que le changement et la réparation de ses pièces endommagées.</p>
<br />
<br />
<br />
<p className="line delay-46">Mémoires de Titine la <strong className="colorbold">survivante. </strong></p>


        </div>
      </div>

       </bodyaccident>
    </div>
   
  );
};

export default TitineAccident;
